:root {
  --body_background: #F7F7F7;
  --body_color: #1e1e1e;
  --link_color: navy;
  --color: 247, 247, 247;
  --alpha: 0.8;
}

[data-theme="dark"]{
  --body_background: #1e1e1e;
  --body_color: #B79266;
  --link_color: cyan;
  --color: 28, 28, 28;
  --alpha: 0.8;
}

*{
  
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: var(--body_color);
      transition: all  0.5s ease-in-out;
      scroll-behavior: smooth; 
    
}

body {
  margin: 0;
  background-color: var(--body_background);
  max-width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
