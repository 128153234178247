.content-body{
    margin-right: 17%;
}


.home-hero{
    background: linear-gradient(to right, rgba(var(--color), var(--alpha)), rgba(var(--color), var(--alpha))), url(/src/assets/1.jpg) no-repeat top fixed;
    border-bottom: 1px solid var(--body_color);
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: 80% 70%;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: all  0.2s ease-in-out;
}

.about-hero{
    background: linear-gradient(to right, rgba(var(--color), var(--alpha)), rgba(var(--color), var(--alpha))), url(/src/assets/1.jpg) no-repeat top fixed;
    border-bottom: 1px solid var(--body_color);
    width: 100%;
    height: 30vh;
    background-size: cover;
    background-position: 80% 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: all  0.2s ease-in-out;
}

.hero-logo{
 width: 15%;
  margin: auto;
  display: block;
}


#scroll-btn{
    color: var(--body_color);
    position: absolute;
    left: 0;
    margin-right: 15%;
    right: 0;
    font-size: 3em;
    bottom: 2vw;
    animation: move-down 1.5s infinite;
    cursor: pointer;
}

.home-gallery{
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    border-bottom: 1px solid var(--body_color);
}

.gallery-margin{
    margin-bottom: 5vh;
}

.parallax-inside-div{
    height: 23vh;
    width: 5vh;
}

.about-text{
    text-align: justify;
    margin-top: 5%;
}

.about-image{
    height: 90vh;
}

.filter-title{
    font-weight: 100;
    display: block;
    font-size: 0.8em;
}



.projects-filter{
    font-weight: 100;
    font-size: 0.8em;
    display: block;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;


    color: var(--body_color);
    background-color: transparent;
    padding: 1vh 1vh 1vh 0;
    border-radius: 0;

}

.projects-filter:active{
    border-bottom:1px solid var(--body_color);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    color: var(--body_color);
     outline: none;
  box-shadow: none;
}


.projects-filter:focus{
    outline: none;
  box-shadow: none;
  border-bottom:1px solid var(--body_color);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: var(--body_color);
}

.projects-filter:hover{
    border-bottom:1px solid var(--body_color);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    color: var(--body_color);
    background: transparent;
    outline: none;
  box-shadow: none;
}


#grid {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a ";
}

#grid-2 {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: 
    "a a a ";
}

.projects-image{
    width:  50vh;
    height: 30vh;
    object-fit: cover;
    margin-bottom: 5vh;
}

.filter-position{
    margin-left: 0;
}

.home-image{
    width:  50vh;
    height: 30vh;
    display: block;
    object-fit: cover;
    margin: 0 2vh 5vh 0;
    transition: .5s ease;
    backface-visibility: hidden;
}

.home-image-container:hover .home-image{
    opacity: 0.3;
}

.home-image-container:hover .projects-image{
    opacity: 0.3;
}

.home-image-container:hover .textbox{
    opacity: 1;
}

.textbox {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.detail-image{
    width: 90%;
    height: 50%;
    object-fit: cover;
    display: block;
    margin: auto;
    margin-bottom: 2vh;
}

.detail-title{
    margin-top: 5vh;
    font-size: 1.2em;
    font-weight: 100;
    display: inline-flex;
}

.back-icons{
display: inline-flex ;
font-size: 1.4em;
font-weight: 100;
background-color: transparent;
border: none;
padding: 0px;
margin-right: 2vh;
}

.back-icons:hover{
    background-color: transparent;
    color: var(--body_color);
}

.home-image-container{
    display: flex;
    justify-content: center;
    position: relative;
}


.contact-title{
    height: 100vh;
    float: right;
    padding-top: 49vh;
    font-weight: 100;
}

.contact-icon{
    font-size: 1.2em !important;
    margin-right: 1vh;
}







@keyframes move-down{
    80%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(3vh);
        opacity: 0;
    }
}



@media(max-width: 576px){

    .hero-logo{
        width: 40%;
    }
    .content-body{
        margin-right: 0;
    }

    .about-text{
        margin-right: 5%;
    }

    #scroll-btn{
        margin-right: 0%;
        bottom: 4vw;
    }

    #grid {
        grid-template-areas: "a a";
    }


    .projects-image{
        width:  15vh;
        height: 15vh;
    }

    .filter-position{
        margin-left: 3vh;
    }

     #grid-2 {
        grid-template-areas:  "a a";
    }

    .home-image{
        width:  20vh;
        height: 20vh;
        margin-bottom: 5vh;
        margin-right: 2vh;
    }

    .contact-title{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: auto;
        float: none;
        padding: 0;
    }

    .home-hero{
        background-position: 10% 50% ;
    }

    .about-image{
        height: 50vh;
    }
    
}


@media (min-width: 600px){
    .content-body{
        margin-right: 19%;
        }
        .hero-logo{
        width: 40%;
    }
    #scroll-btn{
        margin-right: 25%;
        bottom: 8vw;
    }
    #grid {
        grid-template-areas: "a a ";
    }
    .filter-position{
        margin-left: 0;
    }

    .about-image{
        height: 50vh;
    }


    .projects-image{
        width:  15vh;
        height: 15vh;
        margin-bottom: 2vh;
        }
        #grid-2 {
        grid-template-areas: 
        "a a";
        }
        .home-image{
        width:  20vh;
        height: 20vh;
        margin-bottom: 2vh;
    }

    .contact-title{
        padding-top: 50vh;
    }

}

@media(min-width:1000px) {
    .content-body{
        margin-right: 12%;
        }

    .hero-logo{
        width: 15%;
    }

    #scroll-btn{
        margin-right: 15%;
        bottom: 2vw;
    }

    .about-image{
    height: 130vh;}

    #grid {
        grid-template-areas: "a a a ";
    }


    .projects-image{
        width:  20vh;
        height: 20vh;
     }

    #grid-2 {
        grid-template-areas: "a a a";
    }

    .home-image{
        width:  28vh;
        height: 20vh;
        margin-bottom: 2vh;
    }

    .contact-title{
        padding-top: 40vh;
    }





}

@media(min-width:1200px) {
    .content-body{
        margin-right: 11%;
    }

    .about-image{
    height: 90vh;}

    .projects-image{
    width:  50vh;
    height: 30vh;
    }

    .home-image{
    width:  35vh;
    height: 30vh;
    margin-bottom: 2vh;
}
#grid {
    grid-template-areas: "a a ";
}

.projects-image{
    width: 40vh;
    height: 30vh;
}

}

@media(min-width:1400px) {


    .projects-image{
        width: 50vh;
        height: 30vh;
    }

    .contact-title{
        padding-top: 49vh;
    }
    .home-image{
        width:  45vh;
        height: 30vh;
        margin-bottom: 2vh;
    }

}

@media screen 
    and (min-width : 768px) 
    and (min-height: 1024px) { 
        .contact-title{
            padding-top: 54vh !important;
        }
}

@media screen 
    and (min-width : 1024px) 
    and (min-height: 1366px) { 
        .contact-title{
            padding-top: 23vh !important;
        }

        .about-image{
            height: 78vh;
        }
}







