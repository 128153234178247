.sidebar{
    padding-top: 15%;
    padding-left: 1%;
    right: 0;
    width: 15%;
    background-color: var(--body_background);
    position: fixed;
    height: 100%;
    overflow: auto;
}

.nav-link{
    display: block;
    color: var(--body_color);
    padding: 16px 16px 16px 0;
    text-decoration: none;
    font-size: 1.2em;
    background-image: linear-gradient(var(--body_color), var(--body_color));
    background-size: 0% 0.1vh;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    font-weight: 100;
    transition: background-size 0.2s ease-in-out;
}

.nav-link:hover, 
.nav-link:focus, 
.nav-link:active{
    background-size: 100% 0.1vh;
    color: var(--body_color);
}

.toggle-position{
    margin-top: 5vh;
}

.logo-image{
    height: auto;
    max-width: 60%;
    display: block;
}

.nav-number{
    font-size: 0.5em;
}

@media(max-width: 576px){
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        padding-top: 2%;
        text-align: center;
    }
    .nav-link {
        display: inline-block;
        margin-left: 1%;

    }
    .logo-image{
        max-width: 20%;
        display:block;
        margin: auto;
    }
    .toggle-position{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1vh;
        margin-bottom: 2vh;
    }

}


@media (min-width: 600px){
    .sidebar {
        padding-top: 50%;
        padding-left: 2%;
        width: 17%;
    }

}

@media(min-width:1000px) {
    .sidebar{
    padding-top: 15%;
    padding-left: 1%;
    right: 0;
    width: 11%;
    }


}

@media(min-width:1200px) {
    .sidebar{
    padding-top: 15%;
    padding-left: 1%;
    right: 0;
    width: 11%;
    }


}


